.presentation-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  /* width: 100%; */
  background-image: url("../../../public/Images/bg-pres.png");
  background-size: cover;
  background-position: center; /* Centre l'image dans l'élément */
  background-repeat: no-repeat; /* Empêche la répétition de l'image */
  position: relative; /* nécessaire pour l'élément ::before */
  z-index: 1; /* assure que le contenu soit au-dessus du filtre */
}

.presentation-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Superposition avec teinte sombre */
  backdrop-filter: blur(5px); /* Flou appliqué */
  z-index: -1; /* Place la superposition derrière le contenu */
}

/* Copilote please import this font for h2 :https://fonts.googleapis.com/css?family=Roboto */
.presentation-content h3 {
  font-size: 1.5rem;
  font-family: "https://fonts.googleapis.com/css?family=Roboto";
  color: #fff;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.presentation-content p {
  font-size: 1rem;
  font-family: "https://fonts.googleapis.com/css?family=Roboto";
  color: #fff;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.presentation-content h1 {
  font-size: 2.5rem;
  font-family: Rob;
  color: #fff;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
