.layout-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 80px 0 0 0;
  background-color: #333333;
  color: #fff;
  font-family: "Lato" sans-serif;
}
.layout-footer {
  background-color: #333333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-size: 0.8rem;
  font-weight: bold;
}
