.skills-container {
  position: relative;
  padding: 10px;
  margin: 10px 0;
}

.logo-marquee {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.logo-track {
  display: inline-flex;
  white-space: nowrap; /* Empêche le retour à la ligne */
  animation: scroll 20s alternate infinite;
}

.logo-track img {
  height: 100px;
  margin-right: 70px;
}

@media (max-width: 768px) {
  .logo-track img {
    height: 70px; /* Ajustement de la taille des logos pour les petits écrans */
    margin-right: 50px;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(-100%);
  }
}
